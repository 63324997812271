<template>
  <div>
    <crud-table
      v-if="brandList.length"
      ref="crudTable"
      :list-items="listItems"
      :brand-list="brandList"
      :show-brands-filter="true"
      :show-search-filter="true"
      :showCreate="false"
      :is-editable="false"
      :is-deleteble="false"
      :isDisabledDeletedFilter="true"
      :has-rides-to-invoice="true"
      :hidden-rides-to-invoice-filter="true"
      endpoint="passengers"
      heading="generate customers invoices"
      row-click-path="/tables/passengers"
      row-click-query-string="?tab=combined"
      is-row-click-new-tab
      @getData="getData"
    />

    <edit-dialog
      :show.sync="editDialog.display"
      :max-width="'600'"
      @dialogConfirm="editDialog.saveFunction">
      <template>
        <v-container grid-list-xl>
          <v-layout
            row
            wrap>
            <v-flex
              v-if="editDialog.type != 'edit'"
              xs12>
              <users-input
                v-model="editDialog.data.user"
                @changeUser="(inputUser) => { editDialog.data.user = inputUser.id }" />
            </v-flex>
            <v-flex
              xs12>
              <address-input
                :address="editDialog.data.address"
                label="Address"
                @onChange="(val) => { editDialog.data.address = val}"/>
            </v-flex>
            <v-flex
              xs12>
              <v-textarea
                v-model="editDialog.data.description"
                label="Description"
              />
            </v-flex>
            <v-flex
              xs12
            >
              <v-textarea
                v-model="editDialog.data.adminNote"
                label="Admin note"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <group-switch
                :value="editDialog.data.insure"
                :default-value="insuranceOptionValues.ask"
                :option-values="insuranceOptionValues"
                label="Insure"
                @onChange="onInsuranceChange" />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                v-if="brandList.length"
                :items="brandList"
                :rules="[v => v.length>0 || 'Please select at least 1 brand']"
                v-model="editDialog.data.brands"
                :item-text="(val) => { return val.name }"
                clearable
                item-value="id"
                multiple
                label="Brands"
              />
            </v-flex>

            <v-flex
              xs12
              sm6>
              <v-select
                :items="carPositionList"
                v-model="editDialog.data.options.position"
                label="Position in car"
              />
            </v-flex>
            <v-flex
              xs12
              sm6>
              <v-select
                :items="driverDressList"
                v-model="editDialog.data.options.driverDress"
                label="Driver dress"
              />
            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <group-switch
                :value="editDialog.data.options.carryLuggage"
                label="Carry luggage"
                @onChange="onCarryLuggageChange" />

            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <group-switch
                :value="editDialog.data.options.informalLanguage"
                label="Informal language"
                @onChange="onInformalLanguageChange" />
            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <group-switch
                :value="editDialog.data.options.keepDoorsOpen"
                label="Keep doors open"
                @onChange="onKeepDoorsOpenChange" />
            </v-flex>
            <v-flex
              xs12
              sm6
            >
              <group-switch
                :value="editDialog.data.options.playMusic"
                label="Play music"
                @onChange="onPlayMusicChange" />
            </v-flex>

            <v-flex
              xs6
            >
              <v-switch
                v-model="editDialog.data.payOnInvoice"
                label="Pay on invoice"
              />
            </v-flex>
            <v-flex
              v-if="editDialog.type === 'edit'"
              xs12>
              <v-btn
                color="primary"
                @click="sendWelcome()">Send welcome e-mail</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </edit-dialog>
  </div>
</template>

<script>
import CrudTable from '../../../modules/admin/CrudTable.vue'
import UsersInput from '../../../modules/components/UsersInput.vue'
import AddressInput from '../../../modules/components/AddressInput.vue'
import Request from '../../../helpers/request'
import Brands from '../../../helpers/brands'
import { PASSENGER_CAR_POSITIONS, DRIVER_DRESSES, INSURE_PASSENGER_ASK, INSURE_PASSENGER_ALWAYS, INSURE_PASSENGER_NEVER } from '../../../constants/common'
import GroupSwitch from 'Components/GroupSwitch'

export default {
  components: { CrudTable, GroupSwitch, UsersInput, AddressInput },
  mixins: [Request, Brands],
  data () {
    return {
      brandList: [],
      csvData: [],
      listItems: [
        { text: 'ID', value: 'id' },
        { text: 'User id', value: 'user', textValue: 'id' },
        { text: 'Brands', value: 'brands', objectKey: 'name' },
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Address', value: 'address' },
        { text: 'Reference', value: 'reference' },
        { text: 'Insure', value: 'insure' },
        { text: '', value: null }
      ],
      insuranceOptionValues: {
        on: { text: 'ON', value: INSURE_PASSENGER_ALWAYS },
        middle: { text: 'ASK', value: INSURE_PASSENGER_ASK },
        off: { text: 'OFF', value: INSURE_PASSENGER_NEVER }
      },
      loading: true,
      data: [],
      carPositionList: PASSENGER_CAR_POSITIONS,
      driverDressList: DRIVER_DRESSES,
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {
          options: {},
          brands: []
        }
      }
    }
  },
  async beforeMount () {
    try {
      this.brandList = await this.fetchBrands()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    onCarryLuggageChange (value) {
      this.editDialog.data.options.carryLuggage = value
    },
    onInformalLanguageChange (value) {
      this.editDialog.data.options.informalLanguage = value
    },
    onKeepDoorsOpenChange (value) {
      this.editDialog.data.options.keepDoorsOpen = value
    },
    onPlayMusicChange (value) {
      this.editDialog.data.options.playMusic = value
    },
    onInsuranceChange (value) {
      this.editDialog.data.insure = value
    },
    getData (passengers) {
      this.csvData = passengers.map(passenger => ({
        passenger: `${passenger.name || '-'}`,
        'address business': passenger.addressBusiness || '-',
        'address private': passenger.addressPrivate || '-',
        email: passenger.user.email || '-',
        phone: passenger.user.phone ? passenger.user.phone.number : '-',
        description: passenger.description || '-',
        id: passenger.id
      }))
    },
    createPassenger () {
      this.editDialog.display = true
      this.editDialog.type = 'create'
      this.editDialog.data = {
        options: {
          carryLuggage: null,
          informalLanguage: null,
          keepDoorsOpen: null,
          playMusic: null
        },
        brands: []
      }

      this.editDialog.saveFunction = () => {
        let body = {
          data: {
            user: this.editDialog.data.user,
            description: this.editDialog.data.description,
            address: this.editDialog.data.address,
            insure: this.editDialog.data.insure,
            brands: this.editDialog.data.brands.map(brand => brand.id || brand),
            options: this.editDialog.data.options,
            payOnInvoice: this.editDialog.data.payOnInvoice,
            adminNote: this.editDialog.data.adminNote
          }
        }

        this.request('POST', `/passengers`, body, ({ data }) => {
          this.editDialog.display = false
          this.$refs.crudTable.requestData()
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Passagier is aangemaakt'
          })
        })
      }
    },
    deletePassenger (id) {
      this.request('DELETE', `/passengers/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData()
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Passagier is verwijderd'
        })
      })
    },
    restorePassenger (id) {
      this.request('PATCH', `/trash/un-delete/passenger/${id}`, {}, ({ data }) => {
        this.$refs.crudTable.requestData({ deleted: 1 })
        this.$notify({
          group: 'topRight',
          type: 'warning',
          text: 'Customer is restored'
        })
      })
    },
    editPassenger (data) {
      this.editDialog.display = true
      this.editDialog.data = this.gFunc.deepCopy(data)
      this.editDialog.type = 'edit'

      this.editDialog.data.user = this.editDialog.data.user.id

      this.editDialog.saveFunction = () => {
        let body = {
          data: {
            'description': this.editDialog.data.description,
            'address': this.editDialog.data.address,
            'insure': this.editDialog.data.insure,
            'brands': this.editDialog.data.brands.map(brand => brand.id || brand),
            'options': this.editDialog.data.options
          }
        }

        this.request('PATCH', `/passengers/${data.id}`, body, ({ data }) => {
          this.editDialog.display = false
          this.$refs.crudTable.requestData()
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Passagier is gewijzigd'
          })
        })
      }
    },
    sendWelcome () {
      let body = {
        data: {
          passenger: this.editDialog.data.id
        }
      }

      this.request('POST', '/emailing/passenger-set-password', body, ({ data }) => {
        this.$notify({
          group: 'topRight',
          type: 'success',
          text: 'Welcome message has been sent!'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
