<template>
  <div>
    <div>
      {{ label }}
    </div>
    <div class="items-wrapper">
      <div
        :class="{ item: true, 'active-item': val === options.on.value }"
        @click="setValue(options.on.value)">
        {{ options.on.text }}
      </div>
      <div
        :class="{ item: true, 'active-item': val === options.middle.value }"
        @click="setValue(options.middle.value)">
        {{ options.middle.text }}
      </div>
      <div
        :class="{ item: true, 'active-item': val === options.off.value }"
        @click="setValue(options.off.value)">
        {{ options.off.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optionValues: {
      type: Object || undefined || null,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: String || null,
      default: null
    },
    value: {
      default: null
    },
    onChange: {
      type: Function,
      default: () => {
        return null
      }
    }
  },
  data () {
    return {
      val: null,
      options: {
        on: {
          text: 'ON',
          value: true
        },
        middle: {
          text: 'ASK',
          value: null
        },
        off: {
          text: 'OFF',
          value: false
        }
      }
    }
  },
  beforeMount () {
    if (this.$props.optionValues) {
      this.options = this.$props.optionValues
    }

    this.val = this.$props.defaultValue || this.$props.value
  },
  methods: {
    setValue (val) {
      this.val = val
      this.$emit('onChange', val)
    }
  },
  watch: {
    value: {
      deep: true,
      handler () {
        this.setValue(this.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .items-wrapper {
    display: inline-flex;
  }

  .item {
    cursor: pointer;
    color: #ffffff;
    width: 65px;
    padding: 5px 0;
    text-align: center;
    background-color: rgba(0,0,0,0.54);
  }

  .active-item {
    background-color: #41b883;
    cursor: default;
  }
</style>
